import React from 'react';
import Layout from '../../components/Layout';
import Img from "gatsby-image";
import { Link, graphql } from 'gatsby';

const Projects = ({ data }) => {
    const projects = data.projects.nodes;
    const contact = data.contact.siteMetadata.contact;

    const cardStyle = {
        width: '300px',
        margin: '10px',
        padding: '20px',
        backgroundColor: '#353535',
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // Removing fixed height and overflow hidden allows the card to adjust to content
    };

    const contentStyle = {
        // Adding flex-grow here ensures this part of the card can grow and the buttons stick to the bottom
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    };

    return (
        <Layout>
            <div style={{ color: '#fff', backgroundColor: '#2a2a2a', padding: '20px', textAlign: 'center' }}>
                <h2>Developer Portfolio</h2>
                <h3>Featuring my 42 Apps: The 'Answer to the Ultimate Question' of Coding</h3>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', marginTop: '10px' }}>
                    {projects.map(project => (
                        <div key={project.id} style={cardStyle}>
                            <div style={contentStyle}> {/* Use the contentStyle here to wrap image, title, and description */}
                                <Link to={"/projects/" + project.frontmatter.slug} style={{ textDecoration: 'none', color: 'inherit', marginBottom: '10px' }}>
                                    <Img
                                        style={{ borderRadius: "10px", overflow: "hidden", transition: "transform 0.3s ease-in-out" }}
                                        imgStyle={{ objectFit: "cover" }}
                                        fluid={project.frontmatter.thumb.childImageSharp.fluid}
                                        onMouseOver={e => e.currentTarget.style.transform = "scale(1.03)"}
                                        onMouseOut={e => e.currentTarget.style.transform = "scale(1)"}
                                    />
                                    <h3 style={{ marginTop: '10px' }}>{ project.frontmatter.title }</h3>
                                    <p style={{
                                        textAlign: 'left',
                                        // Other styles can remain the same
                                    }}>
                                        { project.frontmatter.stack }
                                    </p>
                                </Link>
                            </div>
                            {/* Button row will always stay at the bottom */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Link to={"/projects/" + project.frontmatter.slug} style={{ background: '#ffc801', color: '#110700', padding: '10px 15px', borderRadius: '5px', textDecoration: 'none', flexGrow: 1, marginRight: '5px' }}>More</Link>
                                <a href={project.frontmatter.hyperlink} target="_blank" rel="noopener noreferrer" style={{ background: '#095881', color: 'white', padding: '10px 15px', borderRadius: '5px', textDecoration: 'none', flexGrow: 1, marginLeft: '5px' }}>Link</a>
                            </div>
                        </div>
                    ))}
                </div>
                <p> Hire Me! at <a href={`mailto:${contact}`} style={{ color: '#ffc801' }}>{contact}</a> </p>
            </div>
        </Layout>
    );
}

export default Projects;

export const query = graphql`
  query ProjectsPage {
    projects: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
      nodes {
        frontmatter {
          slug
          stack
          title
          thumb {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          hyperlink
        }
        id
      }
    }
    contact: site {
      siteMetadata {
        contact
      }
    }
  }
`;
